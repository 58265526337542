import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_ADMIN, API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 看板-首页-1-获取某个学校、某个班级、某个开始和结束时间内、某个实验，所有学生的实验记录的结果分析数值
export async function dashboard_index_1(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_index_1`, "post_json", params);
}

// 看板-首页-2-获取某个学校、某个班级、某个开始和结束时间内、某个实验，所有学生的实验记录的分数分析数字
export async function dashboard_index_2(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_index_2`, "post_json", params);
}

// 看板-首页-3-获取某个学校、某个班级、某个开始和结束时间内、某个实验，所有学生的学习行为数据-安排人次、学习人次、完成人次
export async function dashboard_index_3(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_index_3`, "post_json", params);
}

// 看板-获取某个学校的所有教学班列表
export async function dashboard_index_all_clazz_list(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_index_all_clazz_list`, "post_json", params);
}

// 看板-获取某个学校的实验信息列表
export async function getOneSchoolExperimentInfoList(schoolId) {
  return request_async(API_URL_EXP_ADMIN + `admin/experiment/oneSchoolExperimentInfoList?schoolId=${schoolId}`, "get", {});
}

// 看板-详情页-获取某个教学班安排的所有实验id列表
export async function getDashboardClazzExperiments(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_clazz_1`, "post_json", params);
}

// 看板-详情页-获取某个教学班安排的所有实验id列表
export async function getDashboardClazzOneStudentOverview(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_clazz_2`, "post_json", params);
}

// 看板-详情页-获取某个学生某个实验的班级排名
export async function getOneStudentOneExperimentRankOfClass(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/statistic/dashboard_clazz_3`, "post_json", params);
}
