import {
    dashboard_index_1,
    dashboard_index_2,
    dashboard_index_3,
    dashboard_index_all_clazz_list, getDashboardClazzExperiments, getDashboardClazzOneStudentOverview,
    getOneSchoolExperimentInfoList, getOneStudentOneExperimentRankOfClass
} from "@/api/exp/StatisticApi";


/**
 统计model
 */
class StatisticModel {
    // 看板-获取某个学校的所有教学班列表
    static async dashboard_index_all_clazz_list(schoolId) {
        let [res] = await dashboard_index_all_clazz_list({
            schoolId
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-获取某个学校的实验列表
    static async getOneSchoolExperimentInfoList(schoolId) {
        let [res] = await getOneSchoolExperimentInfoList(schoolId)
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-首页-1-获取某个学校、某个班级、某个开始和结束时间内，所有学生所有实验的实验记录的结果分析数值
    static async dashboard_index_1(schoolId, clazzId, startTime, endTime, experimentId) {
        let [res] = await dashboard_index_1({
            schoolId,
            clazzId,
            startTime,
            endTime,
            experimentId
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-首页-2-获取某个学校、某个班级、某个开始和结束时间内、某个实验，所有学生的实验记录的分数分析数字
    static async dashboard_index_2(schoolId, clazzId, startTime, endTime, experimentId) {
        let [res] = await dashboard_index_2({
            schoolId,
            clazzId,
            startTime,
            endTime,
            experimentId
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-首页-3-获取某个学校、某个班级、某个开始和结束时间内、某个实验，所有学生的学习行为数据-安排人次、学习人次、完成人次
    static async dashboard_index_3(schoolId, clazzId, startTime, endTime) {
        let [res] = await dashboard_index_3({
            schoolId,
            clazzId,
            startTime,
            endTime
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-详情页-获取某个教学班安排的所有实验id列表
    static async getDashboardClazzExperiments(clazzId) {
        let [res] = await getDashboardClazzExperiments({
            clazzId
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-详情页-获取某个学生、某个开始和结束时间内、某个实验的实验记录
    static async getDashboardClazzOneStudentOverview(studentId) {
        let [res] = await getDashboardClazzOneStudentOverview({
            studentId,
            startTime: null,
            endTime: null,
            experimentId: null
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 看板-详情页-获取某个学生某个实验的班级排名
    static async getOneStudentOneExperimentRankOfClass(studentId,aClazzId,experimentId,schoolId) {
        let [res] = await getOneStudentOneExperimentRankOfClass({
            studentId,
            clazzId:aClazzId,
            experimentId,
            schoolId
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }
}

export {StatisticModel}
